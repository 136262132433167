import React, { Fragment } from "react"

/**
 * Change the content to add your own bio
 */

export default () => (
  <Fragment>
    A place to for me to post stuff (mostly)
    <br />
    about software development.
  </Fragment>
)
